.name-field {
  width: 100%;
  margin-top: 20px !important;
}

.max-width {
  width: 100%;
}

.margin-grid {
  margin-top: 30px !important;
}

.margin-grid-center {
  margin-top: 30px !important;
  text-align: center;
}
